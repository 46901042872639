import Corpos from "./Corpos";

const AdministracionEmpresas = {
  codigo: 101,
  nome: "Administración de empresas",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const Aleman = {
  codigo: 12,
  nome: "Alemán",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const AmbitoCientificoTecnoloxico = {
  codigo: 815,
  nome: "Ámbito científico-tecnolóxico",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const AmbitoComunicacionEuscaro = {
  codigo: 813,
  nome: "Ámbito de comunicación: éuscaro",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const AmbitoComunicacionFrances = {
  codigo: 810,
  nome: "Ámbito de comunicación: francés",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const AmbitoComunicacionIngles = {
  codigo: 811,
  nome: "Ámbito de comunicación: inglés",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const AmbitoComunicacionCastela = {
  codigo: 812,
  nome: "Ámbito de comunicación: lingua castelá e literatura",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const AmbitoConecementoSocial = {
  codigo: 814,
  nome: "Ámbito de coñecemento social",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const AmbitoComunicacion = {
  codigo: 816,
  nome: "Ámbito de la comunicación",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const AnaliseQuimicaIndustrial = {
  codigo: 102,
  nome: "Análise e química industrial",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const ApoioAreaCienciasTecnoloxia = {
  codigo: 59,
  nome: "Apoio á área de ciencias ou tecnoloxía",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const ApoioAreaLinguaCienciasSocuais = {
  codigo: 58,
  nome: "Apoio á área de língua e ciencias sociais",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const AsesoriaeProcesosImaxePersoal = {
  codigo: 103,
  nome: "Asesoría e procesos de imaxe persoal",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const BioloxiaeXeoloxia = {
  codigo: 8,
  nome: "Bioloxía e xeoloxía",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const ConstruccionsCivisEdificacions = {
  codigo: 104,
  nome: "Construcións civís e edificacións",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const CulturaClasica = {
  codigo: 803,
  nome: "Cultura clásica",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const Debuxo = {
  codigo: 9,
  nome: "Debuxo",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const DiscapacidadesSensoriais = {
  codigo: 902,
  nome: "Discapacidades sensoriales",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const Economia = {
  codigo: 61,
  nome: "Economía",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const EducacionFisica = {
  codigo: 17,
  nome: "Educación física",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const EducadoresCEI = {
  codigo: 55,
  nome: "Educadores CEI",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const Especialista = {
  codigo: 700,
  nome: "Especialista (Corpo 590)",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const EspecialistaFutbol = {
  codigo: 701,
  nome: "Especialista (Corpo 590): Fútbol",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const EspecialistaFutbolSala = {
  codigo: 702,
  nome: "Especialista (Corpo 590): Fútbol Sala",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const EspecialistaProteccionCivilAmbitoInspeccioneCoordinacion = {
  codigo: 711,
  nome: "Especialista (Corpo 590): Protección Civil, ámbito Inspección e Coordinación",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const EspecialistaProteccionCivilAmbitoIntervencionOperativa = {
  codigo: 712,
  nome: "Especialista (Corpo 590): Protección Civil, ámbito Intervención operativa en emerxencias",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const EspecialistaVela = {
  codigo: 710,
  nome: "Especialista (Corpo 590): Vela",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const Filosofia = {
  codigo: 1,
  nome: "Filosofía",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const FisicaeQuimica = {
  codigo: 7,
  nome: "Física e química",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const FormacioneOrientacionLaboral = {
  codigo: 105,
  nome: "Formación e orientación laboral",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const ComunicacionValenciano = {
  codigo: 817,
  nome: "F.P.A.: Comunicación valenciano",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const ComunicacionValencianoIngles = {
  codigo: 818,
  nome: "F.P.A: Comunicación valenciano/ingles",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const Frances = {
  codigo: 10,
  nome: "Francés",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const Grego = {
  codigo: 2,
  nome: "Grego",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const HostalariaeTurismo = {
  codigo: 106,
  nome: "Hostalaría e turismo",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const Informatica = {
  codigo: 107,
  nome: "Informática",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const InformaticaBUP = {
  codigo: 56,
  nome: "Informática de BUP",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const Ingles = {
  codigo: 11,
  nome: "Inglés",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const IntervencionSociocomunitaria = {
  codigo: 108,
  nome: "Intervención sociocomunitaria",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const Italiano = {
  codigo: 13,
  nome: "Italiano",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const Latin = {
  codigo: 3,
  nome: "Latín",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const LinguaCastela = {
  codigo: 4,
  nome: "Lingua castelá e literatura",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const LinguaGalega = {
  codigo: 53,
  nome: "Lingua e literatura galega",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const Matematicas = {
  codigo: 6,
  nome: "Matemáticas",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const Multimedia = {
  codigo: 800,
  nome: "Multimedia",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const Musica = {
  codigo: 16,
  nome: "Música",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const NavegacioneInstalacionsMarinas = {
  codigo: 109,
  nome: "Navegación e instalacións mariñas",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const OrganizacioneProcdeMantementoVehiculos = {
  codigo: 111,
  nome: "Organización e proc. de mantenemento de vehículos",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const OrganizacioneProxectosdeFabricacionMecanica = {
  codigo: 112,
  nome: "Organización e proxectos de fabricación mecánica",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const OrganizacioneProxectosSistemasEnerxeticos = {
  codigo: 113,
  nome: "Organización e proxectos sistemas enerxéticos",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const OrganizacioneXestionComercial = {
  codigo: 110,
  nome: "Organización e xestión comercial",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const OrientacionEducativa = {
  codigo: 18,
  nome: "Orientación educativa",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const OrientacionVocacionaleProfesional = {
  codigo: 900,
  nome: "Orientación vocacional e profesional",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const Portugues = {
  codigo: 18,
  nome: "Portugués",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const ProcesosdeCultivoAcuicola = {
  codigo: 114,
  nome: "Procesos de cultivo acuícola",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const ProcesosdeProduccionAgraria = {
  codigo: 115,
  nome: "Procesos de producción agraria",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const ProcesosDiagnosticosClinicoseProdutosOrtoprotesicos = {
  codigo: 117,
  nome: "Procesos diagnósticos clínicos e produtos ortoprotésicos",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const ProcesoseMediosdeComunicacion = {
  codigo: 119,
  nome: "Procesos e medios de comunicación",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const ProcesoseProdutosTextilConfeccionePel = {
  codigo: 120,
  nome: "Procesos e produtos de téxtil, confección e pel",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const ProcesoseProdutosdeVidrioeCeramica = {
  codigo: 121,
  nome: "Procesos e produtos de vidrio e cerámica",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const ProcesoseProdutosEnArtesGraficas = {
  codigo: 122,
  nome: "Procesos e produtos en artes gráficas",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const ProcesoseProdutosenMadeiraeMoble = {
  codigo: 123,
  nome: "Procesos e produtos en madeira e moble",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const ProcesosNaIndustriaAlimentaria = {
  codigo: 116,
  nome: "Procesos na industria alimentaria",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const ProcesosSanitarios = {
  codigo: 123,
  nome: "Procesos sanitarios",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const SistemasElectronicos = {
  codigo: 124,
  nome: "Sistemas electrónicos",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const SistemasElectrotecnicoseAutomaticos = {
  codigo: 125,
  nome: "Sistemas electrotécnicos e automáticos",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const Sobredotacion = {
  codigo: 901,
  nome: "Sobredotación",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const Tecnoloxia = {
  codigo: 19,
  nome: "Tecnoloxía",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const TecnoloxiadaFontaneria = {
  codigo: 64,
  nome: "Tecnoloxía da fontanería",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const TrabaladorSocial = {
  codigo: 907,
  nome: "Traballador social/Educación social",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const TrastornosdaConduta = {
  codigo: 905,
  nome: "Trastornos da conduta",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const TrastornosMotoricos = {
  codigo: 903,
  nome: "Trastornos motóricos",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const TRastornosXeneralizadosDoDesenvolvemento = {
  codigo: 904,
  nome: "Trastornos xeneralizados do desenvolvemento",
  corpo: Corpos.ProfesorEnsinoSecundario,
};
const XeografiaEHistoria = {
  codigo: 5,
  nome: "Xeografía e historia",
  corpo: Corpos.ProfesorEnsinoSecundario,
};

const ActividadesCEIS = {
  codigo: 25,
  nome: "Actividades (CEIS)",
  corpo: Corpos.ProfesorFP,
};
const ApoiodeAreaPractica = {
  codigo: 26,
  nome: "Apoio de área práctica",
  corpo: Corpos.ProfesorFP,
};
const AudioProteses = {
  codigo: 250,
  nome: "Audio Próteses",
  corpo: Corpos.ProfesorFP,
};
const CocinaPastelaria = {
  codigo: 201,
  nome: "Cociña e pastelaría",
  corpo: Corpos.ProfesorFP,
};
const EquiposElectronicos = {
  codigo: 202,
  nome: "Equipos electrónicos",
  corpo: Corpos.ProfesorFP,
};
const EspecialistaAnimacionTuristica = {
  codigo: 704,
  nome: "Especialista: Animación turística",
  corpo: Corpos.ProfesorFP,
};
const EspecialistaComunicacion = {
  codigo: 709,
  nome: "Especialista: Comunicación",
  corpo: Corpos.ProfesorFP,
};
const EspecialistaConducionGrupos = {
  codigo: 703,
  nome: "Especialista: Condución de grupos",
  corpo: Corpos.ProfesorFP,
};
const EspecialistaConduciondeGruposeBicicleta = {
  codigo: 709,
  nome: "Especialista: Condución de grupos e bicicleta",
  corpo: Corpos.ProfesorFP,
};
const EspecialistaConducionGruposaCabaloeCoidadosEquinos = {
  codigo: 705,
  nome: "Especialista: Condución grupos a cabalo e coidados equinos",
  corpo: Corpos.ProfesorFP,
};
const EspecialistaCorpo591 = {
  codigo: 700,
  nome: "Especialista (Corpo 591)",
  corpo: Corpos.ProfesorFP,
};
const EspecialistaCorpo591Baloncesto = {
  codigo: 701,
  nome: "Especialista (Corpo 591): Baloncesto",
  corpo: Corpos.ProfesorFP,
};
const EspecialistaEdificacion = {
  codigo: 707,
  nome: "Especialista: Edificación",
  corpo: Corpos.ProfesorFP,
};
const EspecialistaOrganizacionTransporte = {
  codigo: 706,
  nome: "Especialista: Organización transporte",
  corpo: Corpos.ProfesorFP,
};
const Estetica = {
  codigo: 203,
  nome: "Estética",
  corpo: Corpos.ProfesorFP,
};
const FabricacioneInstalaciondeCarpintariaeMoble = {
  codigo: 204,
  nome: "Fabricación e instalación de carpintería e moble",
  corpo: Corpos.ProfesorFP,
};
const Fontaneria = {
  codigo: 230,
  nome: "Fontanería",
  corpo: Corpos.ProfesorFP,
};
const InstalacioneEquiposdeCriaeCultivo = {
  codigo: 207,
  nome: "Instalacións e equipos de cría e de cultivo",
  corpo: Corpos.ProfesorFP,
};
const InstalacionseMantementodeEquiposTermicoseFluidos = {
  codigo: 205,
  nome: "Instalacións e mantemento de equipos térmicos e de fluidos",
  corpo: Corpos.ProfesorFP,
};
const InstalacionsElectrotecnicas = {
  codigo: 206,
  nome: "Instalacións electrotécnicas",
  corpo: Corpos.ProfesorFP,
};
const Laboratorio = {
  codigo: 208,
  nome: "Laboratorio",
  corpo: Corpos.ProfesorFP,
};
const LinguaxedeSignos = {
  codigo: 240,
  nome: "Linguaxe de Signos",
  corpo: Corpos.ProfesorFP,
};
const MantementoAeromecanico = {
  codigo: 702,
  nome: "Mantemento aeromecánico",
  corpo: Corpos.ProfesorFP,
};
const MantementodeVehiculos = {
  codigo: 209,
  nome: "Mantemento de vehículos",
  corpo: Corpos.ProfesorFP,
};
const MaquinasServizoseProducion = {
  codigo: 210,
  nome: "Máquinas, servizos e produción",
  corpo: Corpos.ProfesorFP,
};
const MecanizadoeMantementodeMaquinas = {
  codigo: 211,
  nome: "Mecanizado e mantemento de máquinas",
  corpo: Corpos.ProfesorFP,
};
const OficinadeProxectosdeConstrucion = {
  codigo: 212,
  nome: "Oficina de proxectos de construción",
  corpo: Corpos.ProfesorFP,
};
const OficinadeProxectosdeFabricacionMecanica = {
  codigo: 213,
  nome: "Oficina de proxectos de fabricación mecánica",
  corpo: Corpos.ProfesorFP,
};
const OperaciondeProcesos = {
  codigo: 215,
  nome: "Operación de procesos",
  corpo: Corpos.ProfesorFP,
};
const OperaciondeProducionAgraria = {
  codigo: 216,
  nome: "Operacións de produción agraria",
  corpo: Corpos.ProfesorFP,
};
const OperacionseEquiposdeElaboraciondeProductosAlimentarios = {
  codigo: 214,
  nome: "Operacións e equipos de elaboración de productos alimentarios",
  corpo: Corpos.ProfesorFP,
};
const PatronaxeeConfeccion = {
  codigo: 217,
  nome: "Patronaxe e confección",
  corpo: Corpos.ProfesorFP,
};
const Peiteado = {
  codigo: 218,
  nome: "Peiteado",
  corpo: Corpos.ProfesorFP,
};
const PracticaseHixienistaDental = {
  codigo: 31,
  nome: "Prácticas de hixienista dental",
  corpo: Corpos.ProfesorFP,
};
const PracticasdeMineria = {
  codigo: 218,
  nome: "Prácticas de minería",
  corpo: Corpos.ProfesorFP,
};
const PracticasdeProtesicoDental = {
  codigo: 30,
  nome: "Prácticas de protésico dental",
  corpo: Corpos.ProfesorFP,
};
const PracticasdisminuidosPsiquicos = {
  codigo: 37,
  nome: "Prácticas disminuidos psíquicos",
  corpo: Corpos.ProfesorFP,
};
const PracticaseActividades = {
  codigo: 30,
  nome: "Prácticas e actividades",
  corpo: Corpos.ProfesorFP,
};
const ProcedementosdeDiagnosticoClinicoeOrtoprotesico = {
  codigo: 219,
  nome: "Procedementos de diagnóstico clínico e ortoprotésico",
  corpo: Corpos.ProfesorFP,
};
const ProcedementosSanitarioseAsistenciais = {
  codigo: 220,
  nome: "Procedementos sanitarios e asistenciais",
  corpo: Corpos.ProfesorFP,
};
const ProcesosComerciais = {
  codigo: 221,
  nome: "Procesos comerciais",
  corpo: Corpos.ProfesorFP,
};
const ProcesosdeXestionAdministrativa = {
  codigo: 222,
  nome: "Procesos de xestión administrativa",
  corpo: Corpos.ProfesorFP,
};
const ProduccionTextileTratamentosFisicoQuimicos = {
  codigo: 224,
  nome: "Producción textil e tratamentos físico-químicos",
  corpo: Corpos.ProfesorFP,
};
const ProduciondeArtesGraficas = {
  codigo: 223,
  nome: "Produción de artes gráficas",
  corpo: Corpos.ProfesorFP,
};
const ServiciosaComunidade = {
  codigo: 225,
  nome: "Servizos á comunidade",
  corpo: Corpos.ProfesorFP,
};
const ServiciosDeRestauracion = {
  codigo: 223,
  nome: "Servizos de restauración",
  corpo: Corpos.ProfesorFP,
};
const SistemaseAplicacionsInformaticas = {
  codigo: 227,
  nome: "Sistemas e aplicacións informáticas",
  corpo: Corpos.ProfesorFP,
};
const Soldadura = {
  codigo: 228,
  nome: "Soldadura",
  corpo: Corpos.ProfesorFP,
};
const TallerdeVidroeCeramica = {
  codigo: 21,
  nome: "Taller de vidro e cerámica",
  corpo: Corpos.ProfesorFP,
};
const TecnicaseProcedementosdeImaxeeSon = {
  codigo: 229,
  nome: "Técnicas e procedementos de imaxe e son",
  corpo: Corpos.ProfesorFP,
};
const TecnicosEspecialistas = {
  codigo: 999,
  nome: "Técnicos especialistas",
  corpo: Corpos.ProfesorFP,
};

const AlemanIdiomas = {
  codigo: 1,
  nome: "Alemán",
  corpo: Corpos.ProfesorIdiomas,
};
const ArabeIdiomas = {
  codigo: 2,
  nome: "Árabe",
  corpo: Corpos.ProfesorIdiomas,
};
const ChinesIdiomas = {
  codigo: 4,
  nome: "Chinés",
  corpo: Corpos.ProfesorIdiomas,
};
const EspanolIdiomas = {
  codigo: 6,
  nome: "Español",
  corpo: Corpos.ProfesorIdiomas,
};
const FrancesIdiomas = {
  codigo: 8,
  nome: "Francés",
  corpo: Corpos.ProfesorIdiomas,
};
const GalegoIdiomas = {
  codigo: 9,
  nome: "Galego",
  corpo: Corpos.ProfesorIdiomas,
};
const InglesIdiomas = {
  codigo: 11,
  nome: "Inglés",
  corpo: Corpos.ProfesorIdiomas,
};
const ItalianoIdiomas = {
  codigo: 12,
  nome: "Italiano",
  corpo: Corpos.ProfesorIdiomas,
};
const PortuguesIdiomas = {
  codigo: 15,
  nome: "Portugués",
  corpo: Corpos.ProfesorIdiomas,
};
const RusoIdiomas = {
  codigo: 17,
  nome: "Ruso",
  corpo: Corpos.ProfesorIdiomas,
};
const XaponesIdiomas = {
  codigo: 13,
  nome: "Xaponés",
  corpo: Corpos.ProfesorIdiomas,
};

const Acordeon = {
  codigo: 1,
  nome: "Acordeón",
  corpo: Corpos.CatedráticoMusicaeArtes,
};
const Arpa = {
  codigo: 3,
  nome: "Arpa",
  corpo: Corpos.CatedráticoMusicaeArtes,
};
const Canto = {
  codigo: 6,
  nome: "Canto",
  corpo: Corpos.CatedráticoMusicaeArtes,
};
const Clarinete = {
  codigo: 8,
  nome: "Clarinete",
  corpo: Corpos.CatedráticoMusicaeArtes,
};
const Clave = {
  codigo: 9,
  nome: "Clave",
  corpo: Corpos.CatedráticoMusicaeArtes,
};
const Composicion = {
  codigo: 10,
  nome: "Composición",
  corpo: Corpos.CatedráticoMusicaeArtes,
};
const Concertacion = {
  codigo: 12,
  nome: "Concertación",
  corpo: Corpos.CatedráticoMusicaeArtes,
};
const Contrabaixo = {
  codigo: 14,
  nome: "Contrabaixo",
  corpo: Corpos.CatedráticoMusicaeArtes,
};
const ContrapuntoeFuga = {
  codigo: 15,
  nome: "Contrapunto e fuga",
  corpo: Corpos.CatedráticoMusicaeArtes,
};
const ConxuntoCoraleInstrumental = {
  codigo: 13,
  nome: "Conxunto coral e instrumental",
  corpo: Corpos.CatedráticoMusicaeArtes,
};
const DirecciondeCoro = {
  codigo: 20,
  nome: "Dirección de Coro",
  corpo: Corpos.CatedráticoMusicaeArtes,
};
const DirecciondeOrquesta = {
  codigo: 23,
  nome: "Dirección de Orquestra",
  corpo: Corpos.CatedráticoMusicaeArtes,
};
const EscenaLirica = {
  codigo: 25,
  nome: "Escena Lírica",
  corpo: Corpos.CatedráticoMusicaeArtes,
};
const EsteticaeHistoriadaMusicadaCulturaedaArte = {
  codigo: 28,
  nome: "Estética e historia da música, da cultura e da arte",
  corpo: Corpos.CatedráticoMusicaeArtes,
};
const Etnomusicoloxia = {
  codigo: 33,
  nome: "Etnomusicoloxía",
  corpo: Corpos.CatedráticoMusicaeArtes,
};
const Fagot = {
  codigo: 30,
  nome: "Fagot",
  corpo: Corpos.CatedráticoMusicaeArtes,
};
const FrautadeBico = {
  codigo: 31,
  nome: "Frauta de Bico",
  corpo: Corpos.CatedráticoMusicaeArtes,
};
const FrautaTravesa = {
  codigo: 32,
  nome: "Frauta Travesa",
  corpo: Corpos.CatedráticoMusicaeArtes,
};
const Gaita = {
  codigo: 90,
  nome: "Gaita",
  corpo: Corpos.CatedráticoMusicaeArtes,
};
const Guitarra = {
  codigo: 35,
  nome: "Guitarra",
  corpo: Corpos.CatedráticoMusicaeArtes,
};
const HarmoniaeMelodiaAcompanada = {
  codigo: 2,
  nome: "Harmonía e melodía acompañada",
  corpo: Corpos.CatedráticoMusicaeArtes,
};
const HistoriadaCulturaeDaArte = {
  codigo: 37,
  nome: "Historia da cultura e da arte",
  corpo: Corpos.CatedráticoMusicaeArtes,
};
const HistoriadaMusica = {
  codigo: 39,
  nome: "Historia da música",
  corpo: Corpos.CatedráticoMusicaeArtes,
};
const ImprovisacioneAcompañamento = {
  codigo: 61,
  nome: "Improvisación e acompañamento",
  corpo: Corpos.CatedráticoMusicaeArtes,
};
const InstrumentosdeCordaPulsadadoRenacementoeBarroco = {
  codigo: 92,
  nome: "Instrumentos de Corda Pulsada do Renacemento e Barroco",
  corpo: Corpos.CatedráticoMusicaeArtes,
};
const InstrumentosdePua = {
  codigo: 93,
  nome: "Instrumentos de Púa",
  corpo: Corpos.CatedráticoMusicaeArtes,
};
const InstrumentosHistoricosdeCordaFretada = {
  codigo: 95,
  nome: "Instrumentos Históricos de Corda Fretada",
  corpo: Corpos.CatedráticoMusicaeArtes,
};
const InstrumentosHistoricosdeTecla = {
  codigo: 96,
  nome: "Instrumentos Históricos de Tecla",
  corpo: Corpos.CatedráticoMusicaeArtes,
};
const JazzBateria = {
  codigo: 474,
  nome: "Jazz-batería",
  corpo: Corpos.CatedráticoMusicaeArtes,
};
const JazzContrabaixo = {
  codigo: 476,
  nome: "Jazz-contrabaixo",
  corpo: Corpos.CatedráticoMusicaeArtes,
};
const JazzGuitarra = {
  codigo: 477,
  nome: "Jazz-guitarra",
  corpo: Corpos.CatedráticoMusicaeArtes,
};
const JazzPiano = {
  codigo: 475,
  nome: "Jazz-piano",
  corpo: Corpos.CatedráticoMusicaeArtes,
};
const JazzSaxofon = {
  codigo: 471,
  nome: "Jazz-saxofón",
  corpo: Corpos.CatedráticoMusicaeArtes,
};
const JazzTrombon = {
  codigo: 473,
  nome: "Jazz-trombón",
  corpo: Corpos.CatedráticoMusicaeArtes,
};
const JazzTrompeta = {
  codigo: 472,
  nome: "Jazz-trompeta",
  corpo: Corpos.CatedráticoMusicaeArtes,
};
const LinguaAlema = {
  codigo: 45,
  nome: "Lingua Alemá",
  corpo: Corpos.CatedráticoMusicaeArtes,
};
const LinguaFrancesa = {
  codigo: 46,
  nome: "Lingua Francesa",
  corpo: Corpos.CatedráticoMusicaeArtes,
};
const LinguaInglesa = {
  codigo: 47,
  nome: "Lingua Inglesa",
  corpo: Corpos.CatedráticoMusicaeArtes,
};
const LinguaItaliana = {
  codigo: 48,
  nome: "Lingua Italiana",
  corpo: Corpos.CatedráticoMusicaeArtes,
};
const MusicadeCamara = {
  codigo: 50,
  nome: "Música de cámara",
  corpo: Corpos.CatedráticoMusicaeArtes,
};
const Musicoloxia = {
  codigo: 51,
  nome: "Musicoloxía",
  corpo: Corpos.CatedráticoMusicaeArtes,
};
const Oboe = {
  codigo: 52,
  nome: "Oboe",
  corpo: Corpos.CatedráticoMusicaeArtes,
};
const Pedagoxia = {
  codigo: 57,
  nome: "Pedagoxía",
  corpo: Corpos.CatedráticoMusicaeArtes,
};
const Percusion = {
  codigo: 58,
  nome: "Percusión",
  corpo: Corpos.CatedráticoMusicaeArtes,
};
const Piano = {
  codigo: 59,
  nome: "Piano",
  corpo: Corpos.CatedráticoMusicaeArtes,
};
const ProducioneXestiondeMusicaeArtesEscenicas = {
  codigo: 115,
  nome: "Produción e Xestión de Música e Artes Escénicas",
  corpo: Corpos.CatedráticoMusicaeArtes,
};
const RepertorioconPianoparaInstrumentos = {
  codigo: 98,
  nome: "Repertorio con piano para instrumentos",
  corpo: Corpos.CatedráticoMusicaeArtes,
};
const RepertorioconPianoparaVoz = {
  codigo: 63,
  nome: "Repertorio con piano para voz",
  corpo: Corpos.CatedráticoMusicaeArtes,
};
const Saxofon = {
  codigo: 66,
  nome: "Saxofón",
  corpo: Corpos.CatedráticoMusicaeArtes,
};
const SolfexoeTeoriadaMusica = {
  codigo: 68,
  nome: "Solfexo e teoría da música",
  corpo: Corpos.CatedráticoMusicaeArtes,
};
const TecnoloxiaMusical = {
  codigo: 100,
  nome: "Tecnoloxía Musical",
  corpo: Corpos.CatedráticoMusicaeArtes,
};
const Trombon = {
  codigo: 72,
  nome: "Trombón",
  corpo: Corpos.CatedráticoMusicaeArtes,
};
const Trompa = {
  codigo: 74,
  nome: "Trompa",
  corpo: Corpos.CatedráticoMusicaeArtes,
};
const Trompeta = {
  codigo: 75,
  nome: "Trompeta",
  corpo: Corpos.CatedráticoMusicaeArtes,
};
const Tuba = {
  codigo: 76,
  nome: "Tuba",
  corpo: Corpos.CatedráticoMusicaeArtes,
};
const Viola = {
  codigo: 77,
  nome: "Viola",
  corpo: Corpos.CatedráticoMusicaeArtes,
};
const VioladaGamba = {
  codigo: 102,
  nome: "Viola da Gamba",
  corpo: Corpos.CatedráticoMusicaeArtes,
};
const Violin = {
  codigo: 78,
  nome: "Violín",
  corpo: Corpos.CatedráticoMusicaeArtes,
};
const Violoncello = {
  codigo: 79,
  nome: "Violoncello",
  corpo: Corpos.CatedráticoMusicaeArtes,
};

const AcordeonProfesor = {
  codigo: 1,
  nome: "Acordeón",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const Acrobacia = {
  codigo: 440,
  nome: "Acrobacia",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const ArpaProfesor = {
  codigo: 88,
  nome: "Arpa",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const CantoProfesor = {
  codigo: 4,
  nome: "Canto",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const CantoAplicadoaArteDramatica = {
  codigo: 441,
  nome: "Canto aplicado á arte dramática",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const CaracterizacioneIndumentaria = {
  codigo: 442,
  nome: "Caracterización e indumentaria",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const ClarineteProfesor = {
  codigo: 7,
  nome: "Clarinete",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const ClaveProfesor = {
  codigo: 90,
  nome: "Clave",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const ClavecinistaAcompanante = {
  codigo: 66,
  nome: "Clavecinista acompañante",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const ComposicioneInstrumentacion = {
  codigo: 8,
  nome: "Composición e instrumentación",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const ContrabaixoProfesor = {
  codigo: 10,
  nome: "Contrabaixo",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const ContrapuntoeFugaProfesor = {
  codigo: 11,
  nome: "Contrapunto e fuga",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const ConxuntoCoral = {
  codigo: 73,
  nome: "Conxunto coral",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const Coro = {
  codigo: 9,
  nome: "Coro",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const DanzaAplicadaaArteDramatica = {
  codigo: 443,
  nome: "Danza aplicada á arte dramática",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const DanzaClasica = {
  codigo: 436,
  nome: "Danza clásica",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const DanzaContemporanea = {
  codigo: 437,
  nome: "Danza contemporánea",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const DanzaEspanola = {
  codigo: 13,
  nome: "Danza española",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const DiccioneExpresionOral = {
  codigo: 444,
  nome: "Dicción e expresión oral",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const DireccionEscenica = {
  codigo: 445,
  nome: "Dirección escénica",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const Dramaturxia = {
  codigo: 446,
  nome: "Dramaturxia",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const Esgrima = {
  codigo: 447,
  nome: "Esgrima",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const EspacioEscenico = {
  codigo: 448,
  nome: "Espacio escénico",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const EsteticaeHistoriadaMusicadaCulturaedaArteProfesor = {
  codigo: 19,
  nome: "Estética e historia da música, da cultura e da arte",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const ExpresionCorporal = {
  codigo: 449,
  nome: "Expresión corporal",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const FagotProfesor = {
  codigo: 21,
  nome: "Fagot",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const FlabioniTambori = {
  codigo: 449,
  nome: "Flabiol i tambori",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const Flamenco = {
  codigo: 438,
  nome: "Flamenco",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const FrautadeBicoProfesor = {
  codigo: 24,
  nome: "Frauta de bico",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const FrautaTravesaProfesor = {
  codigo: 23,
  nome: "Frauta travesa",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const FundamentosComposicion = {
  codigo: 2,
  nome: "Fundamentos composición",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const GaitaProfesor = {
  codigo: 99,
  nome: "Gaita",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const GuitarraProfesor = {
  codigo: 26,
  nome: "Guitarra",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const GuitarraFlamenca = {
  codigo: 415,
  nome: "Guitarra flamenca",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const HistoriadaDanza = {
  codigo: 439,
  nome: "Historia da danza",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const HistoriadaMusicaProfesor = {
  codigo: 29,
  nome: "Historia da música",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const Iluminación = {
  codigo: 450,
  nome: "Iluminación",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const InstrumentosdeCordaPulsadadoRenacementoedoBarroco = {
  codigo: 417,
  nome: "Instrumentos de corda pulsada do renacemento e do barroco",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const InstrumentosdePuga = {
  codigo: 418,
  nome: "Instrumentos de puga",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const Interpretacion = {
  codigo: 451,
  nome: "Interpretación",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const InterpretacionconObxectos = {
  codigo: 452,
  nome: "Interpretación con obxectos",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const InterpretacionnoMusical = {
  codigo: 453,
  nome: "Interpretación no musical",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const InterpretacionnoTeatrodoXesto = {
  codigo: 454,
  nome: "Interpretación no teatro do xesto",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const JazzBateriaProfesor = {
  codigo: 474,
  nome: "Jazz-batería",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const JazzContrabaixoProfesor = {
  codigo: 476,
  nome: "Jazz-contrabaixo",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const JazzGuitarraProfesor = {
  codigo: 477,
  nome: "Jazz-guitarra",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const JAzzPianoProfesor = {
  codigo: 475,
  nome: "Jazz-piano",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const JazzSaxofonProfesor = {
  codigo: 471,
  nome: "Jazz-saxofón",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const JazzTrombonProfesor = {
  codigo: 473,
  nome: "Jazz-trombón",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const JazzTrompetaProfesor = {
  codigo: 472,
  nome: "Jazz-trompeta",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const LinguaxeMusical = {
  codigo: 53,
  nome: "Linguaxe musical",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const LinguaxeMusicalArteDramatico = {
  codigo: 478,
  nome: "Linguaxe musical - Arte drámatico",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const LiteraturaDramatica = {
  codigo: 455,
  nome: "Literatura dramática",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const MusicadeCamaraProfesor = {
  codigo: 39,
  nome: "Música de cámara",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const OboeProfesor = {
  codigo: 42,
  nome: "Oboe",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const ÓrganoProfesor = {
  codigo: 43,
  nome: "Órgano",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const Orquestra = {
  codigo: 421,
  nome: "Orquestra",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const PercusionProfesor = {
  codigo: 45,
  nome: "Percusión",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const PianistaAcompananteDanza = {
  codigo: 65,
  nome: "Pianista acompañante (danza)",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const PianistaAcompananteInstrumento = {
  codigo: 64,
  nome: "Pianista acompañante, instrumento",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const PianoProfesor = {
  codigo: 46,
  nome: "Piano",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const RepentizacionTransposicionIntrumentaleAcompanamento = {
  codigo: 50,
  nome: "Repentización, transposición instrumental e acompañamento",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const SaxofonProfesor = {
  codigo: 52,
  nome: "Saxofón",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const TecnicasEscenicas = {
  codigo: 456,
  nome: "Técnicas escénicas",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const TecnicasGraficas = {
  codigo: 457,
  nome: "Técnicas gráficas",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const TenoraiTible = {
  codigo: 425,
  nome: "Tenora i tible",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const TeoriaeHistoriadaArte = {
  codigo: 458,
  nome: "Teoría e historia da arte",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const TeoriaTeatral = {
  codigo: 459,
  nome: "Teoría teatral",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const TrombonProfesor = {
  codigo: 55,
  nome: "Trombón",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const TrombonTuba = {
  codigo: 56,
  nome: "Trombón-tuba",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const TrompaProfesor = {
  codigo: 57,
  nome: "Trompa",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const TrompetaProfesor = {
  codigo: 58,
  nome: "Trompeta",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const TubaProfesor = {
  codigo: 57,
  nome: "Tuba",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const ViolaProfesor = {
  codigo: 58,
  nome: "Viola",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const VioladaGambaProfesor = {
  codigo: 432,
  nome: "Viola da gamba",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const ViolinProfesor = {
  codigo: 61,
  nome: "Violín",
  corpo: Corpos.ProfesorMusicaeArtes,
};
const VioloncelloProfesor = {
  codigo: 63,
  nome: "Violoncello",
  corpo: Corpos.ProfesorMusicaeArtes,
};

const ArqueoloxiaI = {
  codigo: 501,
  nome: "Arqueoloxía I",
  corpo: Corpos.ProfesorArtesPracticaseDeseno,
};
const ArqueoloxiaII = {
  codigo: 507,
  nome: "Arqueoloxía II",
  corpo: Corpos.ProfesorArtesPracticaseDeseno,
};
const Ceramica = {
  codigo: 1,
  nome: "Cerámica",
  corpo: Corpos.ProfesorArtesPracticaseDeseno,
};
const ConservacioneRestauraciondeMateriaisArqueoloxicos = {
  codigo: 2,
  nome: "Conservación e restauración de materiais arqueolóxicos",
  corpo: Corpos.ProfesorArtesPracticaseDeseno,
};
const ConservacioneRestauraciondeObrasEscultoricas = {
  codigo: 3,
  nome: "Conservación e restauración de obras escultóricas",
  corpo: Corpos.ProfesorArtesPracticaseDeseno,
};
const ConservacioneRestauraciondeObrasPictoricas = {
  codigo: 4,
  nome: "Conservación e restauración de obras pictóricas",
  corpo: Corpos.ProfesorArtesPracticaseDeseno,
};
const ConservacioneRestauraciondeTextiles = {
  codigo: 5,
  nome: "Conservación e restauración de téxtiles",
  corpo: Corpos.ProfesorArtesPracticaseDeseno,
};
const ConservacioneRestauraciondoDocumentoGrafico = {
  codigo: 26,
  nome: "Conservación e restauración do documento gráfico",
  corpo: Corpos.ProfesorArtesPracticaseDeseno,
};
const DebuxoArtistico = {
  codigo: 9,
  nome: "Debuxo artístico",
  corpo: Corpos.ProfesorArtesPracticaseDeseno,
};
const DebuxoArtisticoeCor = {
  codigo: 7,
  nome: "Debuxo artístico e cor",
  corpo: Corpos.ProfesorArtesPracticaseDeseno,
};
const DebuxoLineal = {
  codigo: 10,
  nome: "Debuxo lineal",
  corpo: Corpos.ProfesorArtesPracticaseDeseno,
};
const DebuxoTecnico = {
  codigo: 8,
  nome: "Debuxo técnico",
  corpo: Corpos.ProfesorArtesPracticaseDeseno,
};
const DereitoUsualeNocionsdeContabilidadeeCorrespondenciaComercial = {
  codigo: 6,
  nome: "Dereito usual e nocións de contabilidade e correspondencia comercial",
  corpo: Corpos.ProfesorArtesPracticaseDeseno,
};
const DesenodeModa = {
  codigo: 510,
  nome: "Deseño de moda",
  corpo: Corpos.ProfesorArtesPracticaseDeseno,
};
const DesenodeProduto = {
  codigo: 511,
  nome: "Deseño de produto",
  corpo: Corpos.ProfesorArtesPracticaseDeseno,
};
const DesenoGrafico = {
  codigo: 512,
  nome: "Deseño gráfico",
  corpo: Corpos.ProfesorArtesPracticaseDeseno,
};
const DesenoInteriores = {
  codigo: 509,
  nome: "Deseño interiores",
  corpo: Corpos.ProfesorArtesPracticaseDeseno,
};
const DesenoTextil = {
  codigo: 513,
  nome: "Deseño téxtil",
  corpo: Corpos.ProfesorArtesPracticaseDeseno,
};
const EdiciondaArte = {
  codigo: 514,
  nome: "Edición da arte",
  corpo: Corpos.ProfesorArtesPracticaseDeseno,
};
const Fotografia = {
  codigo: 515,
  nome: "Fotografía",
  corpo: Corpos.ProfesorArtesPracticaseDeseno,
};
const HistoriacoaEspecialidadedeArqueoloxia = {
  codigo: 526,
  nome: "Historia coa especialidade de arqueoloxía",
  corpo: Corpos.ProfesorArtesPracticaseDeseno,
};
const HistoriadaArte = {
  codigo: 13,
  nome: "Historia da arte",
  corpo: Corpos.ProfesorArtesPracticaseDeseno,
};
const MatematicasArtes = {
  codigo: 16,
  nome: "Matemáticas",
  corpo: Corpos.ProfesorArtesPracticaseDeseno,
};
const MateriaiseTecnoloxiaConservacioneRestauracion = {
  codigo: 519,
  nome: "Materiais e tecnoloxía: conservación e restauración",
  corpo: Corpos.ProfesorArtesPracticaseDeseno,
};
const MateriaiseTecnoloxiaDeseno = {
  codigo: 520,
  nome: "Materiais e tecnoloxía: deseño",
  corpo: Corpos.ProfesorArtesPracticaseDeseno,
};
const MateriaiseTecnoloxiaCeramicaeVidro = {
  codigo: 518,
  nome: "Materiais e tecnoloxía:cerámica e vidro",
  corpo: Corpos.ProfesorArtesPracticaseDeseno,
};
const MediosAudiovisuais = {
  codigo: 521,
  nome: "Medios audiovisuais",
  corpo: Corpos.ProfesorArtesPracticaseDeseno,
};
const MediosInformaticos = {
  codigo: 522,
  nome: "Medios informáticos",
  corpo: Corpos.ProfesorArtesPracticaseDeseno,
};
const ModeladoeBaleirado = {
  codigo: 18,
  nome: "Modelado e baleirado",
  corpo: Corpos.ProfesorArtesPracticaseDeseno,
};
const OrganizacionIndustrialeLexislacion = {
  codigo: 523,
  nome: "Organización industrial e lexislación",
  corpo: Corpos.ProfesorArtesPracticaseDeseno,
};
const PConservacioneRestauraciondeArqueoloxiaI = {
  codigo: 500,
  nome: "P. Conservación e restauración de arqueoloxía I",
  corpo: Corpos.ProfesorArtesPracticaseDeseno,
};
const PConseervacioneRestauraciondeArqueoloxiaII = {
  codigo: 502,
  nome: "P. Conservación e restauración de arqueoloxía II",
  corpo: Corpos.ProfesorArtesPracticaseDeseno,
};
const PConservacioneRestauraciondeEsculturaII = {
  codigo: 503,
  nome: "P. Conservación e restauración de escultura II",
  corpo: Corpos.ProfesorArtesPracticaseDeseno,
};
const PConseervacioneRestauraciondePinturaII = {
  codigo: 504,
  nome: "P. Conservación e restauración de pintura II",
  corpo: Corpos.ProfesorArtesPracticaseDeseno,
};
const ProcedementoseTecnicasArtisticas = {
  codigo: 508,
  nome: "Procedementos e técnicas artísticas",
  corpo: Corpos.ProfesorArtesPracticaseDeseno,
};
const TecnicasAudiovisuais = {
  codigo: 33,
  nome: "Técnicas Audiovisuais",
  corpo: Corpos.ProfesorArtesPracticaseDeseno,
};
const TecnicasdeDesenoGrafico = {
  codigo: 34,
  nome: "Técnicas de deseño gráfico",
  corpo: Corpos.ProfesorArtesPracticaseDeseno,
};
const TecnicasGraficasIndustriais = {
  codigo: 35,
  nome: "Técnicas gráficas industriais",
  corpo: Corpos.ProfesorArtesPracticaseDeseno,
};
const TeoriadaComunicacion = {
  codigo: 39,
  nome: "Teoría da comunicación",
  corpo: Corpos.ProfesorArtesPracticaseDeseno,
};
const TeoriaePracticasdoDeseno = {
  codigo: 37,
  nome: "Teoría e practicas do deseño",
  corpo: Corpos.ProfesorArtesPracticaseDeseno,
};
const Vidro = {
  codigo: 524,
  nome: "Vidro",
  corpo: Corpos.ProfesorArtesPracticaseDeseno,
};
const Volume = {
  codigo: 525,
  nome: "Volume",
  corpo: Corpos.ProfesorArtesPracticaseDeseno,
};
const Xemoloxia = {
  codigo: 40,
  nome: "Xemoloxía",
  corpo: Corpos.ProfesorArtesPracticaseDeseno,
};
const XoieriaeOrfebreria = {
  codigo: 517,
  nome: "Xoiería e orfebrería",
  corpo: Corpos.ProfesorArtesPracticaseDeseno,
};

const ArtesaniaeOrnamentacionconElementosVexetais = {
  codigo: 601,
  nome: "Artesanía e ornamentación con elementos vexetais",
  corpo: Corpos.MestresdeTallerdeArtesPracticaseDeseno,
};
const BaleiradoeMoldeado = {
  codigo: 65,
  nome: "Baleirado e moldeado",
  corpo: Corpos.MestresdeTallerdeArtesPracticaseDeseno,
};
const BordadoseEncaixes = {
  codigo: 602,
  nome: "Bordados e encaixes",
  corpo: Corpos.MestresdeTallerdeArtesPracticaseDeseno,
};
const CeramicaArtistica = {
  codigo: 6,
  nome: "Cerámica artística",
  corpo: Corpos.MestresdeTallerdeArtesPracticaseDeseno,
};
const ComplementoseAccesorios = {
  codigo: 603,
  nome: "Complementos e accesorios",
  corpo: Corpos.MestresdeTallerdeArtesPracticaseDeseno,
};
const DebuxoPublicitario = {
  codigo: 13,
  nome: "Debuxo publicitario",
  corpo: Corpos.MestresdeTallerdeArtesPracticaseDeseno,
};
const Decoracion = {
  codigo: 8,
  nome: "Decoración",
  corpo: Corpos.MestresdeTallerdeArtesPracticaseDeseno,
};
const Delineacion = {
  codigo: 12,
  nome: "Delineación",
  corpo: Corpos.MestresdeTallerdeArtesPracticaseDeseno,
};
const DesenodeFigurinos = {
  codigo: 15,
  nome: "Deseño de figurinos",
  corpo: Corpos.MestresdeTallerdeArtesPracticaseDeseno,
};
const DesenoGraficoAsistidoporOrdenador = {
  codigo: 107,
  nome: "Deseño gráfico asistido por ordenador",
  corpo: Corpos.MestresdeTallerdeArtesPracticaseDeseno,
};
const DouradoePolicromia = {
  codigo: 16,
  nome: "Dourado e policromía",
  corpo: Corpos.MestresdeTallerdeArtesPracticaseDeseno,
};
const EbanisteriaArtistica = {
  codigo: 605,
  nome: "Ebanistería artística",
  corpo: Corpos.MestresdeTallerdeArtesPracticaseDeseno,
};
const EncadernacionArtistica = {
  codigo: 606,
  nome: "Encadernación artística",
  corpo: Corpos.MestresdeTallerdeArtesPracticaseDeseno,
};
const Esmaltes = {
  codigo: 21,
  nome: "Esmaltes",
  corpo: Corpos.MestresdeTallerdeArtesPracticaseDeseno,
};
const ForxaArtistica = {
  codigo: 23,
  nome: "Forxa artística",
  corpo: Corpos.MestresdeTallerdeArtesPracticaseDeseno,
};
const FotografiaArtistica = {
  codigo: 27,
  nome: "Fotografía artística",
  corpo: Corpos.MestresdeTallerdeArtesPracticaseDeseno,
};
const FotografiaeProcesosdeReproduccion = {
  codigo: 28,
  nome: "Fotografía e procesos de reproducción",
  corpo: Corpos.MestresdeTallerdeArtesPracticaseDeseno,
};
const Gravado = {
  codigo: 29,
  nome: "Gravado",
  corpo: Corpos.MestresdeTallerdeArtesPracticaseDeseno,
};
const MetalisteriaArtistica = {
  codigo: 41,
  nome: "Metalistería artística",
  corpo: Corpos.MestresdeTallerdeArtesPracticaseDeseno,
};
const ModelismoeMaquetismo = {
  codigo: 609,
  nome: "Modelismo e maquetismo",
  corpo: Corpos.MestresdeTallerdeArtesPracticaseDeseno,
};
const MoldeseReproducions = {
  codigo: 610,
  nome: "Moldes e reproducións",
  corpo: Corpos.MestresdeTallerdeArtesPracticaseDeseno,
};
const Musivaria = {
  codigo: 611,
  nome: "Musivaria",
  corpo: Corpos.MestresdeTallerdeArtesPracticaseDeseno,
};
const Ouriveria = {
  codigo: 47,
  nome: "Ouriveria",
  corpo: Corpos.MestresdeTallerdeArtesPracticaseDeseno,
};
const QuimicaAplicadaaCeramica = {
  codigo: 50,
  nome: "Química aplicada á cerámica",
  corpo: Corpos.MestresdeTallerdeArtesPracticaseDeseno,
};
const RepuxadoenMetal = {
  codigo: 52,
  nome: "Repuxado en metal",
  corpo: Corpos.MestresdeTallerdeArtesPracticaseDeseno,
};
const Serigrafia = {
  codigo: 97,
  nome: "Serigrafía",
  corpo: Corpos.MestresdeTallerdeArtesPracticaseDeseno,
};
const TallaenMadeira = {
  codigo: 58,
  nome: "Talla en madeira",
  corpo: Corpos.MestresdeTallerdeArtesPracticaseDeseno,
};
const TallaenPedraeMadeira = {
  codigo: 60,
  nome: "Talla en pedra e madeira",
  corpo: Corpos.MestresdeTallerdeArtesPracticaseDeseno,
};
const TallerdoMoble = {
  codigo: 67,
  nome: "Taller do moble",
  corpo: Corpos.MestresdeTallerdeArtesPracticaseDeseno,
};
const TecnicasCeramicas = {
  codigo: 613,
  nome: "Técnicas cerámicas",
  corpo: Corpos.MestresdeTallerdeArtesPracticaseDeseno,
};
const TecnicasdeGravadoeEstampacion = {
  codigo: 614,
  nome: "Técnicas de gravado e estampación",
  corpo: Corpos.MestresdeTallerdeArtesPracticaseDeseno,
};
const TecnicasdeMetal = {
  codigo: 618,
  nome: "Técnicas de metal",
  corpo: Corpos.MestresdeTallerdeArtesPracticaseDeseno,
};
const TecnicasdeOrfebreriaePrateria = {
  codigo: 616,
  nome: "Técnicas de orfebrería e pratería",
  corpo: Corpos.MestresdeTallerdeArtesPracticaseDeseno,
};
const TecnicasdePatrinaxeeConfeccion = {
  codigo: 617,
  nome: "Técnicas de patronaxe e confección",
  corpo: Corpos.MestresdeTallerdeArtesPracticaseDeseno,
};
const TecnicasdeXoieria = {
  codigo: 63,
  nome: "Técnicas de xoiería",
  corpo: Corpos.MestresdeTallerdeArtesPracticaseDeseno,
};
const TecnicasMurais = {
  codigo: 619,
  nome: "Técnicas murais",
  corpo: Corpos.MestresdeTallerdeArtesPracticaseDeseno,
};
const TexnicasTextiles = {
  codigo: 620,
  nome: "Técnicas téxtiles",
  corpo: Corpos.MestresdeTallerdeArtesPracticaseDeseno,
};
const TecnicasVidreiras = {
  codigo: 621,
  nome: "Técnicas vidreiras",
  corpo: Corpos.MestresdeTallerdeArtesPracticaseDeseno,
};

const AudicioneLinguaxe = {
  codigo: 37,
  nome: "Audición e Linguaxe",
  corpo: Corpos.Mestres,
};
const CienciasdaNatureza = {
  codigo: 22,
  nome: "Ciencias da natureza",
  corpo: Corpos.Mestres,
};
const CienciasSociaisXeografiaeHistoria = {
  codigo: 21,
  nome: "Ciencias sociais, xeografía e historia",
  corpo: Corpos.Mestres,
};
const EducacionEspecialAudicionLinguaxe = {
  codigo: 61,
  nome: "Educación especial: Audición e Linguaxe",
  corpo: Corpos.Mestres,
};
const EducacionEspecialPedagoxiaTerapeutica = {
  codigo: 60,
  nome: "Educación especial: Pedagoxía Terapéutica",
  corpo: Corpos.Mestres,
};
const EducacionFisicaMestre = {
  codigo: 34,
  nome: "Educación Física",
  corpo: Corpos.Mestres,
};
const EducacionFisica1CicloESO = {
  codigo: 27,
  nome: "Educación física. 1º ciclo da ESO",
  corpo: Corpos.Mestres,
};
const EducacionInfantil = {
  codigo: 31,
  nome: "Educación infantil",
  corpo: Corpos.Mestres,
};
const EducacionPrimaria = {
  codigo: 38,
  nome: "Educación Primaria",
  corpo: Corpos.Mestres,
};
const IdiomaExtranxeiroAleman = {
  codigo: 139,
  nome: "Idioma estranxeiro: Alemán",
  corpo: Corpos.Mestres,
};
const LinguaCastelaeLiteraturaMestre = {
  codigo: 24,
  nome: "Lingua castelá e literatura",
  corpo: Corpos.Mestres,
};
const LinguaExtranxeiraFrances = {
  codigo: 33,
  nome: "Lingua estranxeira: Francés",
  corpo: Corpos.Mestres,
};
const LinguaExtranxeiraFrances1CicloESO = {
  codigo: 26,
  nome: "Lingua estranxeira. Francés. 1º ciclo da ESO",
  corpo: Corpos.Mestres,
};
const LinguaExtranxeiraIngles = {
  codigo: 32,
  nome: "Lingua estranxeira: Inglés",
  corpo: Corpos.Mestres,
};
const LinguaextranxeiraIngles1CicloESO = {
  codigo: 25,
  nome: "Lingua estranxeira. Inglés. 1º ciclo da ESO",
  corpo: Corpos.Mestres,
};
const LinguaGalegoMestre = {
  codigo: 67,
  nome: "Lingua galega",
  corpo: Corpos.Mestres,
};
const MatematicasMestre = {
  codigo: 25,
  nome: "Matemáticas",
  corpo: Corpos.Mestres,
};
const MusicaMestre = {
  codigo: 35,
  nome: "Música",
  corpo: Corpos.Mestres,
};
const Musica1CicloESO = {
  codigo: 28,
  nome: "Música: 1º ciclo da ESO",
  corpo: Corpos.Mestres,
};
const Orientacion = {
  codigo: 39,
  nome: "Orientación",
  corpo: Corpos.Mestres,
};
const PedagoxiaTerapeutica = {
  codigo: 36,
  nome: "Pedagoxía Terapéutica",
  corpo: Corpos.Mestres,
};
const PrazasdeEPeOcionasEscolasFogarouCEE = {
  codigo: 64,
  nome: "Prazas de EP e ocio nas Escolas Fogar ou CEE",
  corpo: Corpos.Mestres,
};
const PrazasdePTeOcionasEscolasFogarouCEE = {
  codigo: 63,
  nome: "Prazas de PT e ocio nas Escolas Fogar ou CEE",
  corpo: Corpos.Mestres,
};
const PsicoloxiaePedagoxia = {
  codigo: 18,
  nome: "Psicoloxía e pedagoxía",
  corpo: Corpos.Mestres,
};

const RelixionCatolicaPrimaria = {
  codigo: 401,
  nome: "Relixión católica (primaria)",
  corpo: Corpos.ProfesordeRelixionPrimaria,
};
const RelixionEvanxelicaPrimaria = {
  codigo: 402,
  nome: "Relixión evanxélica (primaria)",
  corpo: Corpos.ProfesordeRelixionPrimaria,
};

const RelixionCatolicaSecundaria = {
  codigo: 400,
  nome: "Relixión católica (secundaria)",
  corpo: Corpos.ProfesordeRelixionSecundaria,
};
const RelixionEvanxelicaSecundaria = {
  codigo: 403,
  nome: "Relixión evanxélica (secundaria)",
  corpo: Corpos.ProfesordeRelixionSecundaria,
};

const Especialidades = {
  [Corpos.ProfesorEnsinoSecundario.codigo]: [
    AdministracionEmpresas,
    Aleman,
    AmbitoCientificoTecnoloxico,
    AmbitoComunicacionEuscaro,
    AmbitoComunicacionFrances,
    AmbitoComunicacionIngles,
    AmbitoComunicacionCastela,
    AmbitoConecementoSocial,
    AmbitoComunicacion,
    AnaliseQuimicaIndustrial,
    ApoioAreaCienciasTecnoloxia,
    ApoioAreaLinguaCienciasSocuais,
    AsesoriaeProcesosImaxePersoal,
    BioloxiaeXeoloxia,
    ConstruccionsCivisEdificacions,
    CulturaClasica,
    Debuxo,
    DiscapacidadesSensoriais,
    Economia,
    EducacionFisica,
    EducadoresCEI,
    Especialista,
    EspecialistaFutbol,
    EspecialistaFutbolSala,
    EspecialistaProteccionCivilAmbitoInspeccioneCoordinacion,
    EspecialistaProteccionCivilAmbitoIntervencionOperativa,
    EspecialistaVela,
    Filosofia,
    FisicaeQuimica,
    FormacioneOrientacionLaboral,
    ComunicacionValenciano,
    ComunicacionValencianoIngles,
    Frances,
    Grego,
    HostalariaeTurismo,
    Informatica,
    InformaticaBUP,
    Ingles,
    IntervencionSociocomunitaria,
    Italiano,
    Latin,
    LinguaCastela,
    LinguaGalega,
    Matematicas,
    Multimedia,
    Musica,
    NavegacioneInstalacionsMarinas,
    OrganizacioneProcdeMantementoVehiculos,
    OrganizacioneProxectosdeFabricacionMecanica,
    OrganizacioneProxectosSistemasEnerxeticos,
    OrganizacioneXestionComercial,
    OrientacionEducativa,
    OrientacionVocacionaleProfesional,
    Portugues,
    ProcesosdeCultivoAcuicola,
    ProcesosdeProduccionAgraria,
    ProcesosDiagnosticosClinicoseProdutosOrtoprotesicos,
    ProcesoseMediosdeComunicacion,
    ProcesoseProdutosTextilConfeccionePel,
    ProcesoseProdutosdeVidrioeCeramica,
    ProcesoseProdutosEnArtesGraficas,
    ProcesoseProdutosenMadeiraeMoble,
    ProcesosNaIndustriaAlimentaria,
    ProcesosSanitarios,
    SistemasElectronicos,
    SistemasElectrotecnicoseAutomaticos,
    Sobredotacion,
    Tecnoloxia,
    TecnoloxiadaFontaneria,
    TrabaladorSocial,
    TrastornosdaConduta,
    TrastornosMotoricos,
    TRastornosXeneralizadosDoDesenvolvemento,
    XeografiaEHistoria,
  ],
  [Corpos.ProfesorFP.codigo]: [
    ActividadesCEIS,
    ApoiodeAreaPractica,
    AudioProteses,
    CocinaPastelaria,
    EquiposElectronicos,
    EspecialistaAnimacionTuristica,
    EspecialistaComunicacion,
    EspecialistaConducionGrupos,
    EspecialistaConduciondeGruposeBicicleta,
    EspecialistaConducionGruposaCabaloeCoidadosEquinos,
    EspecialistaCorpo591,
    EspecialistaCorpo591Baloncesto,
    EspecialistaEdificacion,
    EspecialistaOrganizacionTransporte,
    Estetica,
    FabricacioneInstalaciondeCarpintariaeMoble,
    Fontaneria,
    InstalacioneEquiposdeCriaeCultivo,
    InstalacionseMantementodeEquiposTermicoseFluidos,
    InstalacionsElectrotecnicas,
    Laboratorio,
    LinguaxedeSignos,
    MantementoAeromecanico,
    MantementodeVehiculos,
    MaquinasServizoseProducion,
    MecanizadoeMantementodeMaquinas,
    OficinadeProxectosdeConstrucion,
    OficinadeProxectosdeFabricacionMecanica,
    OperaciondeProcesos,
    OperaciondeProducionAgraria,
    OperacionseEquiposdeElaboraciondeProductosAlimentarios,
    PatronaxeeConfeccion,
    Peiteado,
    PracticaseHixienistaDental,
    PracticasdeMineria,
    PracticasdeProtesicoDental,
    PracticasdisminuidosPsiquicos,
    PracticaseActividades,
    ProcedementosdeDiagnosticoClinicoeOrtoprotesico,
    ProcedementosSanitarioseAsistenciais,
    ProcesosComerciais,
    ProcesosdeXestionAdministrativa,
    ProduccionTextileTratamentosFisicoQuimicos,
    ProduciondeArtesGraficas,
    ServiciosaComunidade,
    ServiciosDeRestauracion,
    SistemaseAplicacionsInformaticas,
    Soldadura,
    TallerdeVidroeCeramica,
    TecnicaseProcedementosdeImaxeeSon,
    TecnicosEspecialistas,
  ],
  [Corpos.ProfesorIdiomas.codigo]: [
    AlemanIdiomas,
    ArabeIdiomas,
    ChinesIdiomas,
    EspanolIdiomas,
    FrancesIdiomas,
    GalegoIdiomas,
    InglesIdiomas,
    ItalianoIdiomas,
    PortuguesIdiomas,
    RusoIdiomas,
    XaponesIdiomas,
  ],
  [Corpos.CatedráticoMusicaeArtes.codigo]: [
    Acordeon,
    Arpa,
    Canto,
    Clarinete,
    Clave,
    Composicion,
    Concertacion,
    Contrabaixo,
    ContrapuntoeFuga,
    ConxuntoCoraleInstrumental,
    DirecciondeCoro,
    DirecciondeOrquesta,
    EscenaLirica,
    EsteticaeHistoriadaMusicadaCulturaedaArte,
    Etnomusicoloxia,
    Fagot,
    FrautadeBico,
    FrautaTravesa,
    Gaita,
    Guitarra,
    HarmoniaeMelodiaAcompanada,
    HistoriadaCulturaeDaArte,
    HistoriadaMusica,
    ImprovisacioneAcompañamento,
    InstrumentosdeCordaPulsadadoRenacementoeBarroco,
    InstrumentosdePua,
    InstrumentosHistoricosdeCordaFretada,
    InstrumentosHistoricosdeTecla,
    JazzBateria,
    JazzContrabaixo,
    JazzGuitarra,
    JazzPiano,
    JazzSaxofon,
    JazzTrombon,
    JazzTrompeta,
    LinguaAlema,
    LinguaFrancesa,
    LinguaInglesa,
    LinguaItaliana,
    MusicadeCamara,
    Musicoloxia,
    Oboe,
    Pedagoxia,
    Percusion,
    Piano,
    ProducioneXestiondeMusicaeArtesEscenicas,
    RepertorioconPianoparaInstrumentos,
    RepertorioconPianoparaVoz,
    Saxofon,
    SolfexoeTeoriadaMusica,
    TecnoloxiaMusical,
    Trombon,
    Trompa,
    Trompeta,
    Tuba,
    Viola,
    VioladaGamba,
    Violin,
    Violoncello,
  ],
  [Corpos.ProfesorMusicaeArtes.codigo]: [
    AcordeonProfesor,
    Acrobacia,
    ArpaProfesor,
    CantoProfesor,
    CantoAplicadoaArteDramatica,
    CaracterizacioneIndumentaria,
    ClarineteProfesor,
    ClaveProfesor,
    ClavecinistaAcompanante,
    ComposicioneInstrumentacion,
    ContrabaixoProfesor,
    ContrapuntoeFugaProfesor,
    ConxuntoCoral,
    Coro,
    DanzaAplicadaaArteDramatica,
    DanzaClasica,
    DanzaContemporanea,
    DanzaEspanola,
    DiccioneExpresionOral,
    DireccionEscenica,
    Dramaturxia,
    Esgrima,
    EspacioEscenico,
    EsteticaeHistoriadaMusicadaCulturaedaArteProfesor,
    ExpresionCorporal,
    FagotProfesor,
    FlabioniTambori,
    Flamenco,
    FrautadeBicoProfesor,
    FrautaTravesaProfesor,
    FundamentosComposicion,
    GaitaProfesor,
    GuitarraProfesor,
    GuitarraFlamenca,
    HistoriadaDanza,
    HistoriadaMusicaProfesor,
    Iluminación,
    InstrumentosdeCordaPulsadadoRenacementoedoBarroco,
    InstrumentosdePuga,
    Interpretacion,
    InterpretacionconObxectos,
    InterpretacionnoMusical,
    InterpretacionnoTeatrodoXesto,
    JazzBateriaProfesor,
    JazzContrabaixoProfesor,
    JazzGuitarraProfesor,
    JAzzPianoProfesor,
    JazzSaxofonProfesor,
    JazzTrombonProfesor,
    JazzTrompetaProfesor,
    LinguaxeMusical,
    LinguaxeMusicalArteDramatico,
    LiteraturaDramatica,
    MusicadeCamaraProfesor,
    OboeProfesor,
    ÓrganoProfesor,
    Orquestra,
    PercusionProfesor,
    PianistaAcompananteDanza,
    PianistaAcompananteInstrumento,
    PianoProfesor,
    RepentizacionTransposicionIntrumentaleAcompanamento,
    SaxofonProfesor,
    TecnicasEscenicas,
    TecnicasGraficas,
    TenoraiTible,
    TeoriaeHistoriadaArte,
    TeoriaTeatral,
    TrombonProfesor,
    TrombonTuba,
    TrompaProfesor,
    TrompetaProfesor,
    TubaProfesor,
    ViolaProfesor,
    VioladaGambaProfesor,
    ViolinProfesor,
    VioloncelloProfesor,
  ],
  [Corpos.ProfesorArtesPracticaseDeseno.codigo]: [
    ArqueoloxiaI,
    ArqueoloxiaII,
    Ceramica,
    ConservacioneRestauraciondeMateriaisArqueoloxicos,
    ConservacioneRestauraciondeObrasEscultoricas,
    ConservacioneRestauraciondeObrasPictoricas,
    ConservacioneRestauraciondeTextiles,
    ConservacioneRestauraciondoDocumentoGrafico,
    DebuxoArtistico,
    DebuxoArtisticoeCor,
    DebuxoLineal,
    DebuxoTecnico,
    DereitoUsualeNocionsdeContabilidadeeCorrespondenciaComercial,
    DesenodeModa,
    DesenodeProduto,
    DesenoGrafico,
    DesenoInteriores,
    DesenoTextil,
    EdiciondaArte,
    Fotografia,
    HistoriacoaEspecialidadedeArqueoloxia,
    HistoriadaArte,
    MatematicasArtes,
    MateriaiseTecnoloxiaConservacioneRestauracion,
    MateriaiseTecnoloxiaDeseno,
    MateriaiseTecnoloxiaCeramicaeVidro,
    MediosAudiovisuais,
    MediosInformaticos,
    ModeladoeBaleirado,
    OrganizacionIndustrialeLexislacion,
    PConservacioneRestauraciondeArqueoloxiaI,
    PConseervacioneRestauraciondeArqueoloxiaII,
    PConservacioneRestauraciondeEsculturaII,
    PConseervacioneRestauraciondePinturaII,
    ProcedementoseTecnicasArtisticas,
    TecnicasAudiovisuais,
    TecnicasdeDesenoGrafico,
    TecnicasGraficasIndustriais,
    TeoriadaComunicacion,
    TeoriaePracticasdoDeseno,
    Vidro,
    Volume,
    Xemoloxia,
    XoieriaeOrfebreria,
  ],
  [Corpos.MestresdeTallerdeArtesPracticaseDeseno.codigo]: [
    ArtesaniaeOrnamentacionconElementosVexetais,
    BaleiradoeMoldeado,
    BordadoseEncaixes,
    CeramicaArtistica,
    ComplementoseAccesorios,
    DebuxoPublicitario,
    Decoracion,
    Delineacion,
    DesenodeFigurinos,
    DesenoGraficoAsistidoporOrdenador,
    DouradoePolicromia,
    EbanisteriaArtistica,
    EncadernacionArtistica,
    Esmaltes,
    ForxaArtistica,
    FotografiaArtistica,
    FotografiaeProcesosdeReproduccion,
    Gravado,
    MetalisteriaArtistica,
    ModelismoeMaquetismo,
    MoldeseReproducions,
    Musivaria,
    Ouriveria,
    QuimicaAplicadaaCeramica,
    RepuxadoenMetal,
    Serigrafia,
    TallaenMadeira,
    TallaenPedraeMadeira,
    TallerdoMoble,
    TecnicasCeramicas,
    TecnicasdeGravadoeEstampacion,
    TecnicasdeMetal,
    TecnicasdeOrfebreriaePrateria,
    TecnicasdePatrinaxeeConfeccion,
    TecnicasdeXoieria,
    TecnicasMurais,
    TexnicasTextiles,
    TecnicasVidreiras,
  ],
  [Corpos.Mestres.codigo]: [
    AudicioneLinguaxe,
    CienciasdaNatureza,
    CienciasSociaisXeografiaeHistoria,
    EducacionEspecialAudicionLinguaxe,
    EducacionEspecialPedagoxiaTerapeutica,
    EducacionFisicaMestre,
    EducacionFisica1CicloESO,
    EducacionInfantil,
    EducacionPrimaria,
    IdiomaExtranxeiroAleman,
    LinguaCastelaeLiteraturaMestre,
    LinguaExtranxeiraFrances,
    LinguaExtranxeiraFrances1CicloESO,
    LinguaExtranxeiraIngles,
    LinguaextranxeiraIngles1CicloESO,
    LinguaGalegoMestre,
    MatematicasMestre,
    MusicaMestre,
    Musica1CicloESO,
    Orientacion,
    PedagoxiaTerapeutica,
    PrazasdeEPeOcionasEscolasFogarouCEE,
    PrazasdePTeOcionasEscolasFogarouCEE,
    PsicoloxiaePedagoxia,
  ],
  [Corpos.ProfesordeRelixionPrimaria.codigo]: [
    RelixionCatolicaPrimaria,
    RelixionEvanxelicaPrimaria,
  ],
  [Corpos.ProfesordeRelixionSecundaria.codigo]: [
    RelixionCatolicaSecundaria,
    RelixionEvanxelicaSecundaria,
  ],
};

export default Especialidades;
