// import mockUsuario from "./mockUsuario";
// import mockVacantes from "./mockVacantes";

const URL = process.env.REACT_APP_URL;

const getMe = async ({ lista, especialidade, codUser }) => {
  // console.log(`GET ME ${codUser} ${lista}${especialidade}`);
  // return mockUsuario;
  console.log(lista, especialidade, codUser);
  const html = await fetch(
    `${URL}/sustitutos/me?corpo=${lista}&especialidade=${especialidade}&codUsuario=${codUser}`
  );
  return html.json();
};

const getVacantes = async ({ corpo, especialidade }) => {
  // console.log(`GET VACANTES ${corpo}${especialidade}`);
  // return mockVacantes;
  const html = await fetch(
    `${URL}/vacantes?corpo=${corpo}&especialidade=${especialidade}`
  );
  return html.json();
};

const EduServices = {
  getMe,
  getVacantes,
};

export default EduServices;
