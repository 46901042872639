import { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Heading, Button } from "@chakra-ui/react";
import PosicionLista from "./PosicionLista";
import { parseEspecialidade } from "../../utils/parsers";
import SelectLista from "./SelectLista";
import AddUserModal from "../AddUserModal";

const Header = () => {
  const dispatch = useDispatch();
  const { listaActual } = useSelector((s) => s.listas);
  const { nome, apelidos, posicion, postoEspera, corpo, especialidade } =
    useSelector((s) => s.user.user);
  const { userCode, apodo } = useSelector((s) => s.user.userData);
  const [modalOpened, setMenuOpened] = useState(false);

  const especialidadeDetallada = useMemo(() => {
    if (corpo && especialidade) {
      return parseEspecialidade(corpo, especialidade);
    }
    return {};
  }, [corpo, especialidade]);

  useEffect(() => {
    dispatch.user.getMe();
  }, [listaActual, dispatch.user]);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="row"
      backgroundColor={"white"}
    >
      <Box
        p={8}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexDirection="column"
      >
        <Heading>Usuario: {apodo}</Heading>
        <Button
          colorScheme="blue"
          size="sm"
          mt={4}
          mb={4}
          onClick={() => setMenuOpened(true)}
        >
          Cambiar usuario
        </Button>
        <Heading>
          {nome} {apelidos}
        </Heading>
        <Heading as="h3" size="l">
          Corpo: {especialidadeDetallada?.corpo?.nome}
        </Heading>
        <Heading as="h3" size="l">
          Especialidade: {especialidadeDetallada?.nome}
        </Heading>
        <SelectLista />
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          flexDirection="row"
          w="100%"
        >
          <PosicionLista
            label="Posto en espera"
            value={postoEspera}
            previousValue={15}
          />
          <PosicionLista
            label="Posto na lista"
            value={posicion}
            previousValue={15}
          />
        </Box>
      </Box>
      <AddUserModal
        isOpen={modalOpened}
        onClose={() => setMenuOpened(false)}
        onSubmit={(value) => {
          dispatch.user.saveUserData(value);
          setMenuOpened(false);
        }}
        codeUser={userCode}
        apodoUser={apodo}
      />
    </Box>
  );
};
export default Header;
