import { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Button,
  Select,
} from "@chakra-ui/react";
import Corpos from "../../utils/Corpos";
import Especialidades from "../../utils/Especialidades";

const CreateListModal = ({ isOpen, onClose, onSubmit }) => {
  const [corpo, setCorpo] = useState();
  const [especialidade, setEspecialidade] = useState();

  const handleClose = () => {
    setCorpo(undefined);
    setEspecialidade(undefined);
    onClose();
  };

  const handleSubmit = () => {
    const especialidadeSeleccionada = Especialidades[corpo].find(
      (i) => i.codigo === parseInt(especialidade)
    );
    onSubmit({ especialidade: especialidadeSeleccionada });
    handleClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Engadir lista</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <FormControl>
            <FormLabel>Corpo</FormLabel>
            <Select
              placeholder="Selecciona corpo"
              onChange={(v) => {
                setCorpo(v.target.value);
              }}
            >
              {Object.values(Corpos).map((i) => (
                <option key={i.codigo} value={i.codigo}>
                  {i.codigo} - {i.nome}
                </option>
              ))}
            </Select>
          </FormControl>

          {corpo && (
            <FormControl mt={4}>
              <FormLabel>Especialidade</FormLabel>
              <Select
                placeholder="Selecciona especialidade"
                onChange={(v) => {
                  setEspecialidade(v.target.value);
                }}
              >
                {Especialidades[corpo]?.map((i) => (
                  <option key={`${i.nome}-${i.codigo}`} value={i.codigo}>
                    {i.codigo} - {i.nome}
                  </option>
                ))}
              </Select>
            </FormControl>
          )}
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
            Save
          </Button>
          <Button onClick={handleClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateListModal;
