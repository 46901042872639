import { Select, Box } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/react";
import CreateListModal from "../../CreateListModal";
import { parseEspecialidade } from "../../../utils/parsers";

const SelectLista = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { listasUsuario: listas, listaActual } = useSelector((s) => s.listas);
  const dispatch = useDispatch();

  const handleSubmit = ({ especialidade }) => {
    if (!listas || !listas.find((i) => i === especialidade)) {
      dispatch.listas.addListToState(especialidade);
      dispatch.listas.selectLista(especialidade);
    }
  };

  const onDelete = () => {
    if (listaActual) {
      dispatch.listas.removeListaActual();
    }
  };

  const selectLista = (value) => {
    const [corpo, especialidade] = value.target.value.split("-");
    const especialidadeEscollida = parseEspecialidade(corpo, especialidade);
    dispatch.listas.selectLista(especialidadeEscollida ?? {});
  };

  return (
    <Box
      p={4}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      flexDirection="column"
    >
      <Select
        placeholder="Seleciona lista"
        onChange={selectLista}
        value={
          listaActual?.codigo
            ? `${listaActual.corpo.codigo}-${listaActual.codigo}`
            : undefined
        }
      >
        {listas?.map((l) => (
          <option
            key={`${l.corpo.codigo}-${l.codigo}`}
            value={`${l.corpo.codigo}-${l.codigo}`}
          >
            {l.corpo.codigo}
            {l.codigo} - {l.nome}
          </option>
        ))}
      </Select>
      <Box
        p={4}
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="row"
        w="100%"
      >
        <Button ml={4} colorScheme="teal" size="md" onClick={onOpen}>
          Engadir
        </Button>
        <Button ml={4} colorScheme="teal" size="md" onClick={onDelete}>
          Borrar
        </Button>
      </Box>
      <CreateListModal
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={handleSubmit}
      />
    </Box>
  );
};

export default SelectLista;
