import { Box, Text, Link } from "@chakra-ui/react";

const Footer = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      flexDirection="row"
      backgroundColor={"white"}
      paddingRight={4}
      paddingLeft={4}
    >
      <Text fontSize="xl">
        Aplicación creada por
        <Link
          color="teal.500"
          href="https://www.linkedin.com/in/alfouz"
          mr={1}
          ml={1}
          isExternal
        >
          alfouz.
        </Link>
      </Text>
      <Text fontSize="lg" textAlign="right">
        Versión 0.1 Funcionalidade final non implementada, só para probas
      </Text>
    </Box>
  );
};
export default Footer;
