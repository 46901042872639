/* eslint-disable react-hooks/exhaustive-deps */
import "./App.css";
import MainPage from "./pages/MainPage";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch.listas.initListas();
  }, []);

  return (
    <div className="App">
      <MainPage />
    </div>
  );
}

export default App;
