import { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Button,
  Input,
} from "@chakra-ui/react";

const AddUserModal = ({ isOpen, onSubmit, onClose, codeUser, apodoUser }) => {
  const [userCode, serUserCode] = useState(codeUser);
  const [apodo, setApodo] = useState(apodoUser);

  const handleClose = () => {
    onClose && onClose();
  };

  const handleSubmit = () => {
    onSubmit({ userCode, apodo });
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Engadir usuario</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <FormControl>
            <FormLabel>Codigo de Usuario</FormLabel>
            <Input
              placeholder="Codigo de usuario"
              onChange={(v) => {
                serUserCode(v.target.value);
              }}
              type="number"
              value={userCode}
            />
            <FormLabel>Apodo para o usuario</FormLabel>
            <Input
              placeholder="Apodo para o usuario"
              onChange={(v) => {
                setApodo(v.target.value);
              }}
              value={apodo}
            />
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={handleSubmit}
            disabled={!(userCode && apodo)}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddUserModal;
