import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Box,
} from "@chakra-ui/react";
import { parseDate } from "../../utils/datesFunctions";

const VacanciesTable = () => {
  const dispatch = useDispatch();
  const { vacantes, listaActual } = useSelector((s) => {
    return {
      vacantes: s.vacantes?.vacantes,
      listaActual: s.listas?.listaActual,
    };
  });

  useEffect(() => {
    if (listaActual.codigo) {
      dispatch.vacantes.getVacantes(listaActual);
    }
  }, [listaActual, dispatch.vacantes]);

  return (
    <Box
      p={8}
      display="flex"
      alignItems="flex-start"
      flexDirection="column"
      flex={1}
    >
      <TableContainer width="100%" backgroundColor="white">
        <Table variant="striped" size="md">
          <Thead>
            <Tr>
              <Th>Centro</Th>
              <Th>Teléfono</Th>
              <Th>Data</Th>
              <Th>Data Fin</Th>
              <Th>Motivo</Th>
              <Th>Xornada</Th>
            </Tr>
          </Thead>
          <Tbody>
            {vacantes?.vacantes?.map((v) => {
              return (
                <Tr key={`${v.centro}${v.fecha}`}>
                  <Td>{v.centro}</Td>
                  <Td>{v.telefono}</Td>
                  <Td>{parseDate(v.fecha)}</Td>
                  <Td>{parseDate(v.dFin)}</Td>
                  <Td>{v.motivo}</Td>
                  <Td>{v.xornada}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};
export default VacanciesTable;
