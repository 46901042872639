import functions from "../../persistentStorage/localStorage";

const listsKey = "listas";

const model = {
  state: {
    listasUsuario: [],
    listaActual: {},
  },
  effects: (dispatch) => ({
    initListas() {
      const listasPrevias = functions.loadData(listsKey);
      if (listasPrevias) {
        dispatch.listas.setListasUsuario(listasPrevias);
        dispatch.listas.setListaUsuario(listasPrevias[0]);
      }
    },
    addListToState(payload) {
      dispatch.listas.addNewList(payload);
    },
    selectLista(payload) {
      dispatch.listas.setListaUsuario(payload);
    },
    removeListaActual(_, rootState) {
      const { listasUsuario, listaActual } = rootState.listas;
      console.log(listasUsuario, listaActual);
      dispatch.listas.setListasUsuario(
        listasUsuario.filter(
          (lU) =>
            lU.codigo !== listaActual.codigo ||
            lU.corpo.codigo !== listaActual.corpo.codigo
        )
      );
    },
  }),
  reducers: {
    setListasUsuario(state, payload) {
      functions.saveData(payload, listsKey);
      return {
        ...state,
        listasUsuario: payload,
      };
    },
    setListaUsuario(state, payload) {
      return {
        ...state,
        listaActual: payload,
      };
    },
    addNewList(state, payload) {
      functions.saveData([...state.listasUsuario, payload], listsKey);
      return {
        ...state,
        listasUsuario: [...state.listasUsuario, payload],
      };
    },
  },
};

export default model;
