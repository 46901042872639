import { init } from "@rematch/core";
import User from "./models/userModel";
import Listas from "./models/listasModel";
import Vacantes from "./models/vacantesModel";

const models = {
  user: User,
  listas: Listas,
  vacantes: Vacantes,
};
const store = init({
  models,
});
export const { dispatch } = store;
export default store;
