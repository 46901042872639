import { Stat, StatLabel, StatNumber } from "@chakra-ui/react";

const PosicionLista = ({ label, value, previousValue }) => {
  // const newValue = value - previousValue;
  // const type = newValue < previousValue ? "increase" : "decrease";
  return (
    <Stat
      minWidth={116}
      alignItems="center"
      display="flex"
      justifyContent="center"
    >
      <StatLabel>{label}</StatLabel>
      <StatNumber display="flex" justifyContent="center">
        {value}
      </StatNumber>
      {/* <StatHelpText display="flex" justifyContent="center">
        <StatArrow type={type} />
        {Math.abs(newValue)}
      </StatHelpText> */}
    </Stat>
  );
};

export default PosicionLista;
