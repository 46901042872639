import Corpos from "./Corpos";
import Especialidades from "./Especialidades";

export const parseCorpo = (corpo) => {
  const values = Object.values(Corpos);
  return values?.find((v) => v.codigo === parseInt(corpo, 10));
};

export const parseEspecialidade = (corpo, especialidade) => {
  const especialidadesCorpo = Especialidades[parseInt(corpo, 10)];
  return especialidadesCorpo?.find(
    (v) => v.codigo === parseInt(especialidade, 10)
  );
};
