import EduServices from "../../services/EduServices";

const model = {
  state: {
    vacantes: [],
  },
  effects: (dispatch) => ({
    async getVacantes(payload, rootState) {
      const {
        codigo: especialidade,
        corpo: { codigo: corpo },
      } = payload;
      const response = await EduServices.getVacantes({ corpo, especialidade });
      dispatch.vacantes.setVacantes(response);
    },
  }),
  reducers: {
    setVacantes(state, payload) {
      return {
        ...state,
        vacantes: payload,
      };
    },
  },
};

export default model;
