const ProfesorEnsinoSecundario = {
  codigo: 590,
  nome: "Profesor de ensino secundario",
};
const ProfesorFP = {
  codigo: 591,
  nome: "Profesor técnico de formación profesional",
};
const ProfesorIdiomas = {
  codigo: 592,
  nome: "Profesor de escolas oficiais de idiomas",
};
const CatedráticoMusicaeArtes = {
  codigo: 593,
  nome: "Catedrático de música e artes escénicas",
};
const ProfesorMusicaeArtes = {
  codigo: 594,
  nome: "Profesor técnico de formación profesional",
};
const ProfesorArtesPracticaseDeseno = {
  codigo: 595,
  nome: "Profesor de artes prácticas e deseño",
};
const MestresdeTallerdeArtesPracticaseDeseno = {
  codigo: 596,
  nome: "Mestres de taller de artes prácticas e deseño",
};
const Mestres = {
  codigo: 597,
  nome: "Mestres",
};
const ProfesordeRelixionPrimaria = {
  codigo: 701,
  nome: "Profesor de relixión primaria",
};
const ProfesordeRelixionSecundaria = {
  codigo: 702,
  nome: "Mestres",
};

const Corpos = {
  ProfesorEnsinoSecundario,
  ProfesorFP,
  ProfesorIdiomas,
  CatedráticoMusicaeArtes,
  ProfesorMusicaeArtes,
  ProfesorArtesPracticaseDeseno,
  MestresdeTallerdeArtesPracticaseDeseno,
  Mestres,
  ProfesordeRelixionPrimaria,
  ProfesordeRelixionSecundaria,
};

export default Corpos;
