import { useEffect } from "react";
import Header from "../../components/Header";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "./styles";
import VacanciesTable from "../../components/VacanciesTable";
import { Box, CircularProgress } from "@chakra-ui/react";
import AddUserModal from "../../components/AddUserModal";
import Footer from "../../components/Footer";

const MainPage = () => {
  const dispatch = useDispatch();
  const { userData } = useSelector((s) => s.user);
  useEffect(() => {
    dispatch.user.getUserData();
  }, [dispatch.user]);

  useEffect(() => {
    if (userData?.userCode) {
      dispatch.user.getMe();
    }
  }, [dispatch.user, userData?.userCode]);

  return (
    <Container>
      {userData?.userCode ? (
        <>
          <Header />
          <VacanciesTable />
          <Footer />
        </>
      ) : (
        <Box
          w="100%"
          h="100%"
          display="flex"
          justifyContent="center"
          alignItems="100%"
        >
          <CircularProgress
            isIndeterminate
            color="green.300"
            size="100px"
            thickness="8px"
            display="flex"
            alignItems="center"
          />
          <AddUserModal
            isOpen={true}
            onSubmit={(value) => dispatch.user.saveUserData(value)}
          />
        </Box>
      )}
    </Container>
  );
};

export default MainPage;
