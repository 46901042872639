import EduServices from "../../services/EduServices";
import functions from "../../persistentStorage/localStorage";

const userCodeKey = "usercode";
// const customCodUSer = 1837736;

const model = {
  state: {
    user: {},
    loading: false,
    userData: {}, // {userCode, apodo}
  },
  effects: (dispatch) => ({
    async getMe(payload, rootState) {
      console.log(rootState);
      const especialidade = rootState.listas.listaActual;
      if (especialidade.codigo) {
        dispatch.user.setLoading(true);
        if (!rootState.user.loading) {
          const myData = await EduServices.getMe({
            lista: especialidade?.corpo?.codigo,
            especialidade: especialidade?.codigo,
            codUser: rootState.user.userData?.userCode,
          });
          dispatch.user.setUser(myData);
          dispatch.user.setLoading(false);
        }
      }
    },
    getUserData() {
      const userKey = functions.loadData(userCodeKey);
      dispatch.user.setUserData(userKey);
    },
    saveUserData(payload) {
      dispatch.user.setUserData(payload);
      functions.saveData(payload, userCodeKey);
    },
  }),
  reducers: {
    setUser(state, payload) {
      return {
        ...state,
        user: payload,
      };
    },
    setUserData(state, payload) {
      return {
        ...state,
        userData: payload,
      };
    },
    setLoading(state, payload) {
      return {
        ...state,
        loading: payload,
      };
    },
  },
};

export default model;
