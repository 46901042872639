const key = "listas-profesorado-galicia-key";

export const saveData = (state, customKey) => {
  localStorage.setItem(
    `${key}-${customKey || "default"}`,
    JSON.stringify(state)
  );
};
export const loadData = (customKey) => {
  return JSON.parse(localStorage.getItem(`${key}-${customKey || "default"}`));
};

const functions = { saveData, loadData };

export default functions;
